
import { Component, Prop, Vue } from 'vue-property-decorator';
import truncate from 'lodash/truncate';
import { openErrorSnackbar, openSuccessSnackbar, randId } from '@/utils/components';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import NewlineText from '@/components/NewlineText.vue';

@Component({
  components: {
    GDialog,
    GButton,
    NewlineText,
  },
})
export default class OverflowText extends Vue {
  @Prop({ type: String, required: false }) text!: string;
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: Number, default: 100 }) length!: number;
  @Prop({ type: Boolean }) breakNewlines!: boolean;
  @Prop({ type: Boolean }) oneLine!: boolean;

  isOpen = false;
  id = randId();

  get hasOverflow() {
    return this.text.length > this.length;
  }

  get truncatedText(): string {
    return truncate(this.text, { length: this.length });
  }

  get splitText() {
    return this.text.split(/\r?\n/);
  }

  close() {
    this.isOpen = false;
  }
  copy() {
    navigator.clipboard
      .writeText(this.text)
      .then(() => {
        openSuccessSnackbar.call(this, 'Copied to clipboard');
        this.close();
      })
      .catch(err => openErrorSnackbar.call(this, err));
  }

  viewMore() {
    this.isOpen = true;
  }
}
